import * as CadastrosApi from './cadastros/cadastros-api'

import { getReq, putReq, postReq } from "@services/api"

export const client = {
    getById: async (id) => { return await getReq('cliente', `/cliente/${id}`, true) },
    update: async (id, body) => { return await putReq('cliente', `/cliente/completar-cadastro/${id}`, body, true) },
    getOperators: async (clienteId) => { return await getReq('operadoras', `/cliente/operadoras/${clienteId}`, true) },
    getByClientId: async (id) => { return await getReq('cliente', `/cliente/operadoras/${id}`, true) },
    locais_select: async () => { return await getReq('cliente', `/cliente/locais`, true) },
    locais: async (nome) => { return await getReq('cliente', `/cliente/locais/${nome}`, true) },
    getPendencies: async (clientId) => { return await getReq('pendências', `/cadastros/cadastro-completo/${clientId}`, true) },
    getApiKeys: async (clientId) => { return await getReq('chaves de api', `/cliente/api-keys/${clientId}`, true) },
    getDadosHome: async (clientId) => { return await getReq('home', `/cliente/home/${clientId}`, true) },
    pontosAtendimento: async (unidadeId) => { return await getReq('cliente', `/cliente/pontos-atendimento/${unidadeId}`, true) },
    pontosAtendimentoAtivos: async (unidadeId) => { return await getReq('cliente', `/cliente/pontos-atendimento-ativos/${unidadeId}`, true) },
    graficos_home: async (clientId) => { return await getReq('home', `/cliente/home-new/${clientId}`, true) },
    user_data: async (email) => { return await getReq('cliente', `/cliente/dados-usuario/${email}`, true) },
    validateSSO: async (clientId) => { return await getReq('cliente', `/cliente/valida-sso-cliente/${clientId}`, true) },
    registerSSO: async (body) => { return await postReq('cliente', '/cliente/sso-cliente', body, true) },
    updateSSO: async (body, clientId) => { return await putReq('cliente', `/cliente/sso-cliente/${clientId}`, body, true) },
    getSSO: async (clientId) => { return await getReq('cliente', `/cliente/sso-cliente/${clientId}`, true) },
    toggleSituationSSO: async (clientId, bool) => { return await getReq('cliente', `/cliente/sso-cliente/${clientId}/${bool}`, true) }
}

export const perfil = {
    update_password_perfil: async (body) => { return await postReq('senha perfil', `/auth/alterar-senha-perfil`, body, true) }
}

export const gerenciamento = {
    getItensTabela: async (query) => { return await getReq('gerenciamento', `/gerenciamento/listar-itens-tabelas?query=${query}`, true) },
    getEstablishment: async (cnpj) => { return await getReq('estabelecimentos', `/gerenciamento/estabelecimento?cnpj=${cnpj}`, true) },
    getOperators: async (operadora, pageNumber, pageSize) => { return await getReq('operadoras', `/gerenciamento/operadoras?Operadora=${operadora}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    obterOperadorasSemPendencias: async (operadora, pageNumber, pageSize) => { return await getReq('operadoras', `/gerenciamento/operadoras-sem-pendencias?Operadora=${operadora}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    getCBOS: async (description, pageNumber, pageSize) => { return await getReq('cbos', `/gerenciamento/cbos?PageNumber=${pageNumber}${description.length > 0 ? `&Busca=${description}` : ''}&PageSize=${pageSize}`, true) },
    getSpecialties: async (specialty) => { return await getReq('especialidades', `/gerenciamento/especialidades${specialty ? `?especialidade=${specialty}` : ''}`, true) },
    getProcedures: async (procedure) => { return await getReq('procedimentos', `/gerenciamento/procedimentos${procedure ? `?procedimento=${procedure}` : ''}`, true) },
    getProfiles: async () => { return await getReq('perfis', `/gerenciamento/perfis`, true) },
    getCities: async (cidade, pageNumber, pageSize) => { return await getReq('cidades', `/gerenciamento/cidades?NomeCidade=${cidade}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    getCouncils: async (busca, pageNumber, pageSize) => { return await getReq('conselhos', `/gerenciamento/conselhos?PageNumber=${pageNumber}&PageSize=${pageSize}${busca?.length > 0 ? `&Busca=${busca}` : ''}`, true) },
    getConvenios: async (convenio) => { return await getReq('convenios', `/gerenciamento/convenios${convenio ? `?nomeCodigo=${convenio}` : ''}`, true) },
    especialidades_principais: async () => { return await getReq('especialidades', `/gerenciamento/especialidades-principais`, true) },
    procedimentos_por_nome_codigo: async (query, tabela, operadoraClienteId) => { return await getReq('procedimentos', `/gerenciamento/procedimentos-autorizacao?query=${query}&tabela=${tabela}&operadoraClienteId=${operadoraClienteId}`, true) },
    procedimentos_por_nome_codigo_especial: async (busca, pageNumber, pageSize) => { return await getReq('procedimentos', `/gerenciamento/procedimentos-pardini?PageNumber=${pageNumber}&PageSize=${pageSize}${busca?.length > 0 ? `&Busca=${busca}` : ''}`, true) },
    listar_matemad: async (busca, pageNumber, pageSize) => { return await getReq('materiais', `/gerenciamento/mat-meds?PageNumber=${pageNumber}&PageSize=${pageSize}${busca?.length > 0 ? `&Busca=${busca}` : ''}`, true) },
    tabelas_tiss: async () => { return await getReq('tiss', `/gerenciamento/tabelas-tiss`, true) },
    getEstabelecimentoCnes: async (cnpj) => { return await getReq('estabelecimentos', `/gerenciamento/dados-por-cnpj/${cnpj}`, true) },
    checar_portal: async (id) => { return await getReq('gerenciamento', `/gerenciamento/possui-portal/${id}`, true) },
    consultarDadosCFM: async (body) => { return await postReq('gerenciamento', `/gerenciamento/consultar-crm`, body, true) },
    pendencias: async (body) => { return await postReq('pendencias', `/gerenciamento/pendencias`, body, true) },
    pendencias_report: async (tipo, body) => { return await postReq('pendencias', `/gerenciamento/pendencias-report/${tipo}`, body, true) }
}

export const autorizacao = {
    consulta: async (body) => { return await postReq('autorizacao', `/autorizador/consulta`, body, true) },
    internacao: async (body) => { return await postReq('autorizacao', `/autorizador/internacao`, body, true) },
    // spsadt: async (body) => { return await postReq('autorizacao', `/autorizador/spsadt`, body, true) },
    mock: async (paramInt) => { return await getReq('autorizacao', `/autorizador/mock?param=${paramInt}`, true) },
    pedido_exame: async (body) => { return await postReq('autorizacao', `/autorizador/pedido-exame`, body, true) },
    atualizar_pedido_exame: async (id, body) => { return await putReq('autorizacao', `/autorizador/alterar-pedido-exame/${id}`, body, true) },
    cancelar_pedido_exame: async (id) => { return await getReq('autorizacao', `/autorizador/cancelar-pedido-exame/${id}`, true) },
    buscar_pedidos_exame: async (clientId, searchValue, pageNumber, pageSize) => { return await getReq('autorizacao', `/autorizador/buscar-pedidos-exame?ClienteId=${clientId}&Busca=${searchValue}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    autorizacao: async (id) => { return await getReq('autorizacao', `/autorizador/autorizacao/${id}`, true) },
    enviar_autorizacao: async (id) => { return await getReq('autorizacao', `/autorizador/enviar-autorizacao/${id}`, true) },
    autorizar_pendente: async (id) => { return await getReq('autorizacao', `/autorizador/autorizar-pendente/${id}`, true) },
    prorrogacao: async (guiaNaOperadora) => { return await getReq('autorizacao', `/autorizador/prorrogacao/${guiaNaOperadora}`, true) },
    buscar_prorrogacao: async (guiaNaOperadora) => { return await getReq('autorizacao', `/autorizador/buscar-prorrogacao/${guiaNaOperadora}`, true) },
    excluir_anexo_guia: async (id, guiaId) => { return await getReq('autorizacao', `/autorizador/excluir-anexo-guia/${id}/${guiaId}`, true) },
    tipos_atendimentos: async () => { return await getReq('autorizacao', `/autorizador/tipos-atendimento`, true) },
    verificar_situacao_autorizacao: async (body) => { return await postReq('autorizacao', `/autorizador/validar-alteracao-autorizacao`, body, true) },
    validar_autorizacao_pendente: async (carteira, id) => { return await getReq('autorizacao', `/autorizador/autorizacao-pendente-operadora/${carteira}/${id}`, true) },
    alterar_consulta: async (id, body) => { return await putReq('autorizacao', `/autorizador/alterar-consulta/${id}`, body, true) },
    alterar_spsad: async (id, body) => { return await putReq('autorizacao', `/autorizador/alterar-spsad/${id}`, body, true) },
    autorizacoes: async (clientId, search, pageNumber, pageSize) => { return await getReq('autorizacao', `/autorizador/autorizacoes?ClienteId=${clientId}${search.length > 0 ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    guias_visualizadas: async (clientId) => { return await getReq('autorizacao', `/autorizador/marcar-todas-visualizadas/${clientId}`, true) },
    alterar_visualizacao: async (guiaId) => { return await getReq('autorizacao', `/autorizador/alterar-visualizacao/${guiaId}`, true) },
    validar_elegibilidade: async (model) => { return await postReq('autorizacao', `/autorizador/elegibilidade`, model, true) },
    token_autorizacao: async (body) => { return await postReq('autorizacao', `/autorizador/token-autorizacao`, body, true) },
    gerar_numero_guia_prestador: async (clientId) => { return await getReq('autorizacao', `/autorizador/guia-prestador/${clientId}`, true) },
    obter_arquivo_report: async (tipo, body) => { return await postReq('autorizacao', `/autorizador/report-historico/${tipo}`, body, true) },
    filtrar_historico: async (body) => { return await postReq('autorizacao', `/autorizador/filtrar-historico`, body, true) },
    print_guia_spsadt: async (guiaId) => { return await getReq('autorizacao', `/autorizador/print-spsadt/${guiaId}`, true) },
    iniciar_autorizacao: async (body) => { return await postReq('autorizacao', `/autorizador/iniciar-autorizacao`, body, true) },
    confirmar_autorizacao: async (body) => { return await postReq('autorizacao', '/autorizador/confirmar-autorizacao', body, true) },
    regra_anexo: async (codigoProcedimento) => { return await getReq('autorizacao', `/autorizador/regra-anexo/${codigoProcedimento}`, true) },
    obter_sessao: async (operadoraClienteId, operadoraClienteCredencialId) => { return await getReq('autorizacao', `/autorizador/obter-sessao/${operadoraClienteId}/${operadoraClienteCredencialId}`, true) },
    print_pdf_autorizacao: async (id) => { return await getReq('autorizacao', `/autorizador/pdf-autorizacao/${id}`, true) },
    validar_pendencia_procedimento: async (body) => { return await postReq('autorizacao', '/autorizador/validar-pendencia-procedimento', body, true) },
    validar_pendencias: async (body) => { return await postReq('autorizacao', '/autorizador/validar-pendencias', body, true) }
}

export const autorizar = {
    autorizacao: async (guiaId) => { return await getReq('autorizar', `/autorizar/autorizacao/${guiaId}`, true) },
    autorizacoes: async (clientId, search, pageNumber, pageSize) => { return await getReq('autorizar', `/autorizar/autorizacoes?ClienteId=${clientId}${search.length > 0 ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    cancelar_autorizacao: async (body) => { return await postReq('autorizacao', `/autorizador/cancelamento-autorizacao`, body, true) },
    cancelamento_pendente: async (body) => { return await postReq('autorizar', `/autorizar/cancelamento-autorizacao-pendente`, body, true) },
    cancelamento_via_ticket: async (body) => { return await postReq('autorizar', '/autorizar/cancelamento-via-ticket', body, true) },
    confirmar_elegibilidade_biometria: async (operadoraClienteId, body) => { return await postReq('autorizar', `/autorizar/confirmar-elegibilidade-biometria/${operadoraClienteId}`, body, true) },
    correlacao_procedimentos: async (body) => { return await postReq('autorizar', `/autorizar/correlacao-procedimentos`, body, true) },
    detalhe_pedido: async (id) => { return await getReq('autorizar', `/autorizar/detalhe-pedido/${id}`, true) },
    data_envio_token: async (tokenId) => { return await getReq('autorizar', `/autorizar/data-envio-token/${tokenId}`, true) },
    pesquisa_beneficiario: async (body) => { return await postReq('autorizar', `/autorizar/pesquisa-beneficiario`, body, true) },
    elegibilidade_biometria: async (operadoraClienteId, body) => { return await postReq('autorizar', `/autorizar/elegibilidade-biometria/${operadoraClienteId}`, body, true) },
    enviar_pedido: async (body) => { return await postReq('autorizar', '/autorizar/send-pedido', body, true) },
    executar_senha: async (body) => { return await postReq('autorizar', '/autorizar/executar-senha', body, true) },
    executar_guia: async (body) => { return await postReq('autorizar', '/autorizar/executar-procedimentos', body, true) },
    filtrar_historico: async (body) => { return await postReq('autorizar', `/autorizar/filtrar-historico`, body, true) },
    listar_pedidos: async (clientId, search, pageNumber, pageSize) => { return await getReq('autorizar', `/autorizar/pedidos?ClienteId=${clientId}${search.length > 0 ? `&Busca=${search}` : ''}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) },
    obter_usuario_guia: async (id) => { return await getReq('autorizar', `/autorizar/obter-usuario-guia/${id}`, true) },
    obter_usuario_pendencia: async (senha) => { return await getReq('autorizar', `/autorizar/obter-usuario-pendencia/${senha}`, true) },
    paciente_biometria: async (operadoraClienteId, clienteId, numeroCarteira) => { return await getReq('autorizar', `/autorizar/paciente-biometria/${operadoraClienteId}/${clienteId}/${numeroCarteira}`, true) },
    pedido_revisado: async (id) => { return await getReq('autorizar', `/autorizar/pedido-revisado/${id}`, true) },
    recebimento_imagens: async (body) => { return await postReq('autorizar', '/autorizar/recebimento-imagens', body, true) },
    reprocessar_anexo: async (body) => { return await postReq('autorizar', `/autorizar/reprocessar-anexo`, body, true) },
    reprocessar_anexo_provider: async (body) => { return await postReq('autorizar', `/autorizar/reprocessar-anexo-provider`, body, true) },
    salvar_beneficiario_elegibilidade: async (clienteId, operadoraId, body) => { return await postReq('autorizar', `/autorizar/salvar-beneficiario-elegibilidade/${clienteId}/${operadoraId}`, body, true) },
    spending_ceiling_ipsemg: async (body) => { return await postReq('autorizar', '/autorizar/ipsemg-spending-ceiling', body, true) },
    spsadt: async (body) => { return await postReq('autorizar', `/autorizar/spsadt`, body, true) },
    token_pedido: async (body) => { return await postReq('autorizar', `/autorizar/token-pedido`, body, true) },
    transcricao_exames: async (body) => { return await postReq('autorizar', `/autorizar/transcricao-exames`, body, true) },
    validar_elegibilidade: async (model) => { return await postReq('autorizar', `/autorizar/elegibilidade`, model, true) },
    validar_token_pedido: async (id) => { return await getReq('autorizar', `/autorizar/validar-token-pedido/${id}`) }
}

export const configuracoes = {
    monitor: async (startDate, endDate, pageNumber, pageSize) => { return await getReq('configuracoes', `/configuracoes/monitoramento?StartDate=${startDate}&EndDate=${endDate}&PageNumber=${pageNumber}&PageSize=${pageSize}`, true) }
}

export const cadastros = { ...CadastrosApi }